<template>
  <div>
    <NavBar />
    <v-breadcrumbs :items="breadCrumbsItems">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :exact="item.exact" :to="item.to" :disabled="item.disabled">
          <span :style="`color: ${item.color}`"> {{ item.text }}</span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <!-- <div v-if="this.tok !== 'new'"> -->
    <div class="mx-3 d-flex align-center">
      <v-img
        v-if="currentCafe.image !== null && currentCafe.image !== undefined"
        max-width="100"
        max-height="100"
        :src="url() + currentCafe.image.path"
        class="ma-4"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0 align-center jystify-ceter">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <h1>{{ currentCafe.nameRu }}</h1>
    </div>

    <v-tabs v-model="selectedTabs" class="ma-5" background-color="transparent" color="red" slider-size="3">
      <v-tab v-if="this.checkPermission([128, 8])" :to="`/control/cafes/${tok}/edit`">Информация о кафе</v-tab>
      <v-tab v-if="this.checkPermission([128])" :to="`/control/cafes/${tok}/tags`">Теги</v-tab>
      <v-tab :to="`/control/cafes/${tok}/products`">Товары</v-tab>
      <v-tab :to="`/control/cafes/${tok}/categories`">Категории</v-tab>
    </v-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import NavBar from '@/components/NavBar';
import config from '@/config/config';

export default {
  data() {
    return {
      editedItem: {
        addressRu: '',
        agentInn: '',
        agentName: '',
        agentPhone: '',
        aproxTimeCook: null,
        nameRu: '',
        openTimeStart: '',
        openTimeEnd: ''
      },
      tok: this.$route.params.cafeToken,
      selectedTabs: null,
      breadCrumbsItems: [
        {
          text: 'Выбор ресторана',
          exact: true,
          disabled: false,
          to: '/control'
        },
        {
          text: 'Выбор продуктов',
          exact: true,
          disabled: false,
          to: `/control/cafes/${this.$route.params.cafeToken}/products`
        }
      ]
    };
  },
  components: {
    NavBar
  },
  computed: {
    currentCafe() {
      if (!Object.keys(this.cafeInfo).length) return {};
      return JSON.parse(JSON.stringify(this.cafeInfo));
    },
    products() {
      let productArray = [];
      if (this.menuList !== undefined && this.menuList !== null && this.menuListResult === true) {
        this.menuList.forEach((product) => {
          productArray.push(product);
        });
      }
      return productArray;
    },
    headers() {
      return [
        {
          text: 'Название',
          value: 'nameRu'
        },
        {
          text: 'Корпоративный',
          value: 'onlyCorpo'
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ];
    },
    ...mapGetters('auth', ['userPerm']),

    ...mapGetters('admin', ['cafeInfo']),
    ...mapGetters('admin', ['menuList']),
    ...mapGetters('admin', ['isMenuListLoading']),
    ...mapGetters('admin', ['menuListResult']),
    ...mapGetters('admin', ['isMenuListError']),
    ...mapGetters('admin', ['menuListErrorMessage'])
  },
  methods: {
    checkPermission(value) {
      return this.userPerm.find((el) => value.includes(el)) !== undefined;
    },
    getCafesProduct() {
      if (this.tok !== 'new') {
        this.fetchMenuList(this.$route.params.cafeToken);
      }
    },
    url() {
      return window.location.origin + '/';
    },
    addCafeClick() {},
    ...mapActions('admin', ['fetchMenuList']),
    ...mapActions('cafe', ['fetchCurrentCafe'])
  },
  mounted() {
    this.getCafesProduct();
  },
  watch: {
    currentCafe(newVal) {
      if (newVal !== null) {
        this.editedItem = newVal;
      }
    }
  }
};
</script>
<style scoped>
.v-breadcrumbs >>> a {
  color: red;
}
.v-image,
video {
  border-radius: 10px;
}
</style>
